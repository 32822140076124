import React from "react";
import { useParams } from "react-router-dom";
import ClassPerformanceClass from "../../components/ClassPerformanceClass/ClassPerformanceClass";
import Layout from "../../components/shared/Layout/Layout";
import { TopMarkList } from "../../components/TopMarkList";

const ClassPerformancePage = () => {
  const { type } = useParams();
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Class Performance`}>
        <ClassPerformanceClass />
      </Layout>
    </div>
  );
};

export default ClassPerformancePage;
