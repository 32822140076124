import React from "react";
// import { AlarmingStudents } from "../../components/AlarmingStudents";
import MarkList from "../../components/MarkList/MarkList";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicTopResult = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={`Merit List`}>
        <MarkList />
      </PublicLayout>
    </div>
  );
};

export default PublicTopResult;
