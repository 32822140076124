import React, { useEffect, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";

import { Link } from "react-router-dom";
import styles from "./BatchList.module.css";
import { connect } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { useModals } from "@mantine/modals";
import { Text } from "@mantine/core";
import { toast } from "react-toastify";
import {
  deleteBatch,
  getBatchList,
  getGradeBatchList,
} from "../../actions/Batch.action";

const BatchList = ({ list, getGradeBatchList, deleteBatch }) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);

  const modals = useModals();

  useEffect(() => {
    if (list === null) {
      getGradeBatchList();
    }
    if (list !== null) {
      setRawList(list);
    }
  }, [list]);

  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this grade",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this grade? This action is destructive
          and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete Batch", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteBatch(id);
    if (check === true) {
      toast.success("Batch deleted successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Container className="pb-4">
      <div className="d-flex justify-content-end align-items-center pb-3 ">
        <div className="text-right">
          <Link to="/admin/batch/add" className={styles.add}>
            <BsFileEarmarkPlus />
          </Link>
        </div>
      </div>
      <Card className="crd p-md-4 pb-md-0 p-2">
        <Card.Body>
          {rawList === null ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: 600 }}
            >
              <Spinner variant="dark" animation="grow" />
            </div>
          ) : rawList.length === 0 ? (
            <span className="text-center fs-4 d-block pb-3">
              No Batch Found
            </span>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Class</th>
                    <th>Batch</th>
                    <th>
                      <div className="d-flex justify-content-center align-items-center">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rawList !== null &&
                    rawList.map((gradeItem, i) => (
                      <tr key={gradeItem.id}>
                        <td>{i + 1}</td>
                        <td>{gradeItem.name}</td>
                        <td colSpan={2}>
                          <Table borderless className="p-0 m-0">
                            <tbody>
                              {gradeItem.shifts.map((sft) =>
                                gradeItem.batches
                                  .filter((item) => item.shiftId === sft.id)
                                  .map((btc, i) => (
                                    <tr
                                      key={sft.id}
                                      className={
                                        i !==
                                        gradeItem.batches.filter(
                                          (item) => item.shiftId === sft.id
                                        ).length -
                                          1
                                          ? "border-bottom"
                                          : ""
                                      }
                                    >
                                      <td>{btc.name}</td>
                                      <td>
                                        <div className="d-flex justify-content-center align-items-center">
                                          <Link
                                            to={`/admin/batch/${btc.id}/edit`}
                                            className={` fw-bold  me-4 ${styles.link}`}
                                          >
                                            <AiFillEdit />
                                          </Link>
                                          <span
                                            onClick={() =>
                                              deleteHandeler(btc.id)
                                            }
                                            className={` fw-bold  ${styles.link}`}
                                          >
                                            <BsTrash />
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                              )}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeBatchList, deleteBatch })(
  BatchList
);
