import React from "react";
import { connect } from "react-redux";
// import logo from "../../Assets/logo.jpeg";

const SectionHeading = ({
  title,
  subtitle,
  batch,
  shift,
  grade,
  data,
  year,
  month,
  customClass,
}) => {
  if (grade) {
  }
  return (
    <div
      className={`d-flex align-items-center justify-content-center flex-column ${customClass}`}
    >
      {/* <img src={logo} style={{ height: 60 }} alt="" /> */}
      <h4 className="pt-4">Notun Kuri Coaching Center</h4>
      {title && <h5>{title}</h5>}
      {subtitle && <span className="d-block h5 fw-normal">{subtitle}</span>}
      <div className="d-flex justify-content-center">
        {data && grade && (
          <span className="d-block h6 fw-bold px-3">
            Class : {data.filter((grd) => grd.id === parseInt(grade))[0]?.name}
          </span>
        )}
        {data && shift && (
          <span className="d-block h6 fw-bold px-3">
            Shift :{" "}
            {
              data
                .filter((grd) => grd.id === parseInt(grade))[0]
                ?.shifts.filter((s) => s.id === parseInt(shift))[0]?.name
            }
          </span>
        )}
        {data && batch && (
          <span className="d-block h6 fw-bold px-3">
            Batch :{" "}
            {
              data
                .filter((grd) => grd.id === parseInt(grade))[0]
                ?.batches.filter((s) => s.id === parseInt(batch))[0]?.name
            }
          </span>
        )}
      </div>
      <div className="d-flex justify-content-center">
        {month && (
          <span className="d-block h6 fw-bold px-3">Month : {month}</span>
        )}
        {year && <span className="d-block h6 fw-bold px-3">Year : {year}</span>}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: state.grade.grade,
});
export default connect(mapStateToProps, null)(SectionHeading);
