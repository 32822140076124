import React, { useEffect, useRef, useState } from "react";
import { Card, Container, Spinner, Table } from "react-bootstrap";
import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getStudentMarkList } from "../../actions/Mark.action";
import { getStudentDetails } from "../../actions/Student.action";
import { months } from "../../constants/MonthsAndYears";
import { getGrade } from "../../utils/getGrade";
import LineChart from "../lineChart/lineChart";
import styles from "./MarkListStudent.module.css";

const MarkListStudent = ({
  list,
  getStudentMarkList,
  id,
  getStudentDetails,
  student,
}) => {
  const [showChart, setShowChart] = useState(true);
  const ref = useRef(null);
  useEffect(() => {
    if (id !== null) {
      getStudentMarkList(id);
      getStudentDetails(id);
    }
  }, [id]);

  return (
    <Container className="pb-4">
      {list !== null && student !== null ? (
        <div className="d-flex justify-content-end">
          {/* {showChart ? (
            <span
              className={styles.chart_icon}
              onClick={() => setShowChart(false)}
            >
              <AiOutlineClose />
            </span>
          ) : (
            <span
              className={styles.chart_icon}
              onClick={() => setShowChart(true)}
            >
              <BsBarChartFill />
            </span>
          )} */}
          <ReactToPrint
            documentTitle={`Marksheet - ${student.name}`}
            content={() => ref.current}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <span className={styles.chart_icon} onClick={handlePrint}>
                  <AiFillPrinter />
                </span>
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </div>
      ) : (
        <></>
      )}
      {student && student.id === parseInt(id) ? (
        <div className="">
          <span className="text-center fs-4 d-block pb-3">
            Student Information
          </span>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td className="fw-bold">Name</td>
                <td>{student.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Roll</td>
                <td>{student.uid}</td>
              </tr>
              <tr>
                <td className="fw-bold">Class</td>
                <td>{student.grade.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Shift</td>
                <td>{student.shift.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Batch</td>
                <td>{student.batch.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">Phone</td>
                <td>{student.phone}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : (
        <></>
      )}
      {list !== null ? (
        <Card className="crd p-md-4 pb-md-0 p-2 mt-4" ref={ref}>
          <Card.Body>
            {list === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : list.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <>
                <span className="text-center fs-4 d-block pb-5">
                  Monthly Individual Class Performance Report
                </span>
                <Table striped bordered hover responsive className="mb-5">
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Year</th>
                      <th>Total Mark</th>
                      {/* <th>Base Mark</th> */}
                      <th>Present</th>
                      <th>Percent</th>
                      <th>Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list !== null &&
                      list.map((markItem, i) => (
                        <tr key={markItem.id}>
                          <td>{months[markItem.month]}</td>
                          <td>{markItem.year}</td>
                          <td>{markItem.total}</td>
                          {/* <td>{markItem.base}</td> */}
                          <td>{markItem.present}</td>
                          <td>{markItem.percent.toFixed(0)}%</td>
                          <td>{getGrade(markItem.percent)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {showChart ? (
                  <div className={styles.chart}>
                    <LineChart data={list} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.student_mark,
  student: state.student.selected_student,
});

export default connect(mapStateToProps, {
  getStudentMarkList,
  getStudentDetails,
})(MarkListStudent);
