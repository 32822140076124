export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
