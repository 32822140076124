import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Layout.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  MdAddChart,
  MdHotelClass,
  MdOutlineCompareArrows,
  MdOutlineSdCardAlert,
} from "react-icons/md";
import { logout } from "../../../actions/Dashboard.action";
import { connect } from "react-redux";
import { FiLogOut } from "react-icons/fi";
import { GoThreeBars } from "react-icons/go";
import { BsCalendarRange } from "react-icons/bs";
import { SiGoogleclassroom } from "react-icons/si";
import {
  BsFillDiagram3Fill,
  BsFillFileEarmarkBarGraphFill,
  BsGraphUp,
  BsStack,
} from "react-icons/bs";
import { BiUser, BiUserPin } from "react-icons/bi";
import {
  AiOutlineEdit,
  AiOutlineHome,
  AiOutlinePrinter,
  AiOutlineStar,
} from "react-icons/ai";
import { HiOutlineUserGroup } from "react-icons/hi";
import { Accordion } from "@mantine/core";

const Layout = ({ logout, children, title }) => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const [type, setType] = React.useState("");

  const logoutHandeler = async () => {
    let check = await logout();
    if (check === true) {
      navigate("/");
    }
  };
  return (
    <div>
      <Container fluid>
        <Row className="position-relative">
          <Col
            md={2}
            className={`px-4 ${styles.wrapper} ${show ? styles.active : ""}`}
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <Link
                to="/"
                className="d-flex align-items-center py-3 text-decoration-none text-dark"
              >
                {/* <img src={logo} alt="" className={styles.logo} /> */}
                {/* <span className="d-block fs-4 ">Angular Esports</span> */}
              </Link>
              <div
                className={`${styles.ham}  ms-auto`}
                onClick={() => setShow(!show)}
              >
                <GoThreeBars />
              </div>
            </div>

            <div className={styles.nav}>
              <NavLink to="/dashboard" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlineHome />
                </span>
                <span className={styles.nav__item_text}>Dashboard</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/grade" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BsFillDiagram3Fill />
                </span>
                <span className={styles.nav__item_text}>Class</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/shift" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BsStack />
                </span>
                <span className={styles.nav__item_text}>Shift</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/batch" className={styles.nav__item}>
                <span className={styles.icon}>
                  <HiOutlineUserGroup />
                </span>
                <span className={styles.nav__item_text}>Batch</span>
              </NavLink>
            </div>

            <div className={styles.nav}>
              <NavLink to="/admin/student" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BiUser />
                </span>
                <span className={styles.nav__item_text}>Student</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink to="/admin/mark/add" className={styles.nav__item}>
                <span className={styles.icon}>
                  <MdAddChart />
                </span>
                <span className={styles.nav__item_text}>Add Result</span>
              </NavLink>
            </div>

            <Accordion multiple value={type} onChange={setType}>
              <Accordion.Item value="operation">
                <Accordion.Control>
                  <BsCalendarRange className="me-2" />
                  Weekly
                </Accordion.Control>
                <Accordion.Panel>
                  <div className={styles.nav}>
                    <NavLink to="/admin/week/add" className={styles.nav__item}>
                      <span className={styles.icon}>
                        <MdAddChart />
                      </span>
                      <span className={styles.nav__item_text}>
                        Add Weekly Result
                      </span>
                    </NavLink>
                  </div>
                  <div className={styles.nav}>
                    <NavLink to="/admin/week/edit" className={styles.nav__item}>
                      <span className={styles.icon}>
                        <AiOutlineEdit />
                      </span>
                      <span className={styles.nav__item_text}>
                        Edit Weekly Result
                      </span>
                    </NavLink>
                  </div>
                  <div className={styles.nav}>
                    <NavLink to="/admin/week/view" className={styles.nav__item}>
                      <span className={styles.icon}>
                        <BsCalendarRange />
                      </span>
                      <span className={styles.nav__item_text}>
                        View Weekly Result
                      </span>
                    </NavLink>
                  </div>
                  <div className={styles.nav}>
                    <NavLink to="/admin/week/top" className={styles.nav__item}>
                      <span className={styles.icon}>
                        <BsCalendarRange />
                      </span>
                      <span className={styles.nav__item_text}>
                        View Weekly Top Result
                      </span>
                    </NavLink>
                  </div>
                  <div className={styles.nav}>
                    <NavLink
                      to="/admin/week/result"
                      className={styles.nav__item}
                    >
                      <span className={styles.icon}>
                        <BsCalendarRange />
                      </span>
                      <span className={styles.nav__item_text}>
                        Weekly Comparison
                      </span>
                    </NavLink>
                  </div>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
            <div className={styles.nav}>
              <NavLink to="/admin/mark/edit" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlineEdit />
                </span>
                <span className={styles.nav__item_text}>Edit Result</span>
              </NavLink>
            </div>

            <div className={styles.nav}>
              <NavLink
                end
                to="/admin/individual-result"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <BiUserPin />
                </span>
                <span className={styles.nav__item_text}>Individual Result</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                end
                to="/admin/class-performance"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <SiGoogleclassroom />
                </span>
                <span className={styles.nav__item_text}>Class Performance</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                end
                to="/admin/class-summary"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <MdHotelClass />
                </span>
                <span className={styles.nav__item_text}>Class Summary</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink end to="/admin/mark" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlinePrinter />
                </span>
                <span className={styles.nav__item_text}>Print Result</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink end to="/admin/view-result" className={styles.nav__item}>
                <span className={styles.icon}>
                  <BsFillFileEarmarkBarGraphFill />
                </span>
                <span className={styles.nav__item_text}>View Result</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                end
                to="/admin/alarming-student"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <MdOutlineSdCardAlert />
                </span>
                <span className={styles.nav__item_text}>Alarming Student</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink end to="/admin/mark/top" className={styles.nav__item}>
                <span className={styles.icon}>
                  <AiOutlineStar />
                </span>
                <span className={styles.nav__item_text}>Merit List</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink end to="/admin/comparison" className={styles.nav__item}>
                <span className={styles.icon}>
                  <MdOutlineCompareArrows />
                </span>
                <span className={styles.nav__item_text}>Grade Comparison</span>
              </NavLink>
            </div>
            <div className={styles.nav}>
              <NavLink
                end
                to="/admin/academic-graph"
                className={styles.nav__item}
              >
                <span className={styles.icon}>
                  <BsGraphUp />
                </span>
                <span className={styles.nav__item_text}>Academic Graph</span>
              </NavLink>
            </div>

            <div className={styles.nav}>
              <div className={styles.nav__item} onClick={logoutHandeler}>
                <span className={styles.icon}>
                  <FiLogOut />
                </span>
                <span className={styles.nav__item_text}>Logout</span>
              </div>
            </div>
          </Col>
          <Col md={10} className="bg-light">
            <div className="d-flex justify-content-end align-items-center py-3">
              <div
                className={`${styles.ham}  me-auto`}
                onClick={() => setShow(!show)}
              >
                <GoThreeBars />
              </div>
              {title ? (
                <h3 className="me-auto ps-4 fs-3 my-auto">{title}</h3>
              ) : (
                <></>
              )}

              {/* <UserInfoTopbar /> */}
            </div>
            <Container>{children}</Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default connect(null, { logout })(Layout);
