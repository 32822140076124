import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import { WeeklyViewResult } from "../../components/WeeklyViewResult";

const WeeklyViewResultPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Weekly Comparison`}>
        <WeeklyViewResult />
      </Layout>
    </div>
  );
};

export default WeeklyViewResultPage;
