import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import { AiFillPrinter } from "react-icons/ai";
import { connect } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { getGradeList } from "../../actions/Grade.action";
import { getWeekResult } from "../../actions/Week.action";
import { years } from "../../constants/MonthsAndYears";
import { getGrade } from "../../utils/getGrade";
import SectionHeading from "../SectionHeading/SectionHeading";
import styles from "./WeeklyViewResult.module.css";

const WeeklyViewResult = ({ list, getGradeList, grades, getWeekResult }) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear().toString());

  const [week, setWeek] = useState(1);
  const [studentId, setStudentId] = useState("");
  const [weekEnd, setWeekEnd] = useState(1);

  const ref = useRef(null);

  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
    if (list !== null && list?.result) {
      setRawList(list?.result);
    }
  }, [list]);

  const selectHandeler = async () => {
    await getWeekResult(week, weekEnd, studentId, year, grade, shift, batch);
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grades && grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" ? (
              <Col
                md={3}
                className="d-md-flex d-none jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="roll" className="d-block">
                  Roll
                </label>
              </div>
              <Form.Control
                onChange={(e) => {
                  setStudentId(parseInt(e.target.value));
                }}
                value={studentId}
                type="text"
                id="roll"
                name="roll"
              ></Form.Control>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="week" className="d-block">
                  Start Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setWeek(parseInt(e.target.value));
                }}
                id="week"
                name="week"
                value={week}
              >
                {Array.from({ length: 52 }, (_, i) => i + 1).map((item, i) => (
                  <option key={i} value={`${i + 1}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="weekEnd" className="d-block">
                  End Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setWeekEnd(parseInt(e.target.value));
                }}
                value={weekEnd}
                id="weekEnd"
                name="weekEnd"
              >
                {Array.from({ length: 52 }, (_, i) => i + 1).map((item, i) => (
                  <option key={i} value={`${i + 1}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
                value={year}
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col
              md={4}
              className="d-flex d-md-none jsutify-content-end align-items-end py-3"
            >
              <Button
                onClick={selectHandeler}
                variant="primary"
                type="submit"
                className="btn_primary"
              >
                Select
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Results Found
              </span>
            ) : (
              <>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Weekly Report from Week ${list.extra.start} to Week ${list.extra.end})`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="p-3" ref={ref}>
                  {list?.extra ? (
                    <SectionHeading
                      title={`Weekly Class Performance Report (Week ${list.extra.start} to Week ${list.extra.end})`}
                      batch={list?.extra.batchId}
                      shift={list?.extra.shiftId}
                      grade={list?.extra.gradeId}
                      year={list?.extra.year}
                    />
                  ) : (
                    <></>
                  )}

                  {list && list?.student ? (
                    <div className="text-center">
                      <hr />
                      <h5>
                        <b>Name:</b> {list.student.name}
                      </h5>
                      <h5>
                        <b>Roll:</b> {list.student.uid}
                      </h5>
                      <hr />
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <span className="text-center fs-6 d-block pb-3">
                    Base mark: {rawList !== null ? `${rawList[0].base}` : ""}
                  </span> */}
                  <div className="d-flex align-items center justify-content-center pb-4">
                    <div className="d-flex justify-content-center pt-4">
                      <span className="d-block pe-3">
                        <span className="fw-bold">A++</span> = (91-100)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A+</span> = (81-90)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A</span> = (71-80)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A-</span> = (61-70)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">B</span> = (51-60)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">C</span> = {"(<51)"}{" "}
                      </span>
                    </div>
                  </div>
                  <Table striped bordered hover className="mb-4">
                    <thead>
                      <tr>
                        <th>Week</th>
                        <th>Percent</th>
                        <th>Grade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rawList !== null &&
                        rawList
                          .sort(function (a, b) {
                            if (a.week < b.week) return -1;
                            if (a.week > b.week) return 1;
                            return 0;
                          })
                          .map((markItem, i) => (
                            <tr key={markItem.id}>
                              <td>Week {markItem.week}</td>
                              <td>{markItem.percent}%</td>
                              <td>{getGrade(markItem.percent)}</td>
                            </tr>
                          ))}
                    </tbody>
                  </Table>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="d-flex">
                      <span className="d-block pe-3">
                        <span className="fw-bold">A++</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A++")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A+</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A+")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A-</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "A-")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">B</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "B")
                            .length
                        }
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">C</span> ={" "}
                        {
                          rawList.filter((l) => getGrade(l.percent) === "C")
                            .length
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.week.week,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getGradeList,
  getWeekResult,
})(WeeklyViewResult);
