import {
  CREATE_GRADE,
  DELETE_GRADE,
  GET_GRADE,
  GET_GRADE_BATCH,
  GET_GRADE_DETAILS,
  UPDATE_GRADE,
} from "../constants/Type";

const initialState = {
  grade: null,
  selected_grade: null,
  loading: true,
};

const gradeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GRADE:
      return {
        ...state,
        grade: payload,
        loading: false,
      };
    case GET_GRADE_BATCH:
      return {
        ...state,
        grade: payload,
        loading: false,
      };
    case GET_GRADE_DETAILS:
      return {
        ...state,
        selected_grade: payload,
        loading: false,
      };

    case DELETE_GRADE:
      return {
        ...state,
        grade: state.grade.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    default:
      return state;
  }
};

export default gradeReducer;
