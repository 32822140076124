import React from "react";
import { AlarmingStudents } from "../../components/AlarmingStudents";
import Layout from "../../components/shared/Layout/Layout";

const AlarmingStudentPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Alarming Students`}>
        <AlarmingStudents />
      </Layout>
    </div>
  );
};

export default AlarmingStudentPage;
