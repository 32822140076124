import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createWeek, getWeekStudentForBatch } from "../../actions/Week.action";
import { years } from "../../constants/MonthsAndYears";
import styles from "./AddWeeklyForm.module.css";

const AddWeeklyForm = ({
  extra,
  grades,
  getWeekStudentForBatch,
  createWeek,
}) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [week, setWeek] = useState(1);
  const [total, setTotal] = useState(100);
  const [totalShow, setTotalShow] = useState(100);

  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState(null);

  const selectHandeler = async () => {
    if (!total) {
      toast.error("Please enter total mark!");
      return;
    }
    const list = await getWeekStudentForBatch(year, week, batch);
    if (!list) {
      return;
    }
    setStudents(
      list.map((l) => ({
        total,
        gained: 0,
        id: l.id,
        studentId: l.id,
        uid: l.uid,
        name: l.name,
        phone: l.phone,
      }))
    );
    setTotalShow(total);
  };

  const submitHandeler = async () => {
    setLoading(true);
    let check = await createWeek(week, students, year);
    if (check === true) {
      setStudents(null);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const deleteHandeler = (id) => {
    setStudents([...students.filter((st) => st.id !== id)]);
  };
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" && batch !== "" && total ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="week" className="d-block">
                  Week
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setWeek(parseInt(e.target.value));
                }}
                id="week"
                name="week"
                value={week}
              >
                {Array.from({ length: 52 }, (_, i) => i + 1).map((item, i) => (
                  <option key={i} value={`${i + 1}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
                value={year}
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="total" className="d-block">
                  Total Mark
                </label>
              </div>
              <Form.Control
                name="total"
                type="text"
                value={total}
                onChange={(e) => setTotal(parseFloat(e.target.value))}
                className={`w-100`}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {students ? (
        <Card bg="white" text="dark" className={`crd shadow mt-4`}>
          <Card.Body>
            <h1 className="fs-4 fw-normal py-3">
              Fill the student marks{" "}
              <b>{extra && extra?.week ? `for week : ${extra.week}` : ""}</b>{" "}
              and <b> Total Mark: {totalShow}</b>
            </h1>
            <Row>
              <Col
                md={1}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="d-block fw-bold">Roll</span>
              </Col>
              <Col md={6} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Name</span>
              </Col>
              <Col md={4} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Gained Mark</span>
              </Col>

              <Col md={1} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Delete</span>
              </Col>
            </Row>
            <hr />
            {students.map((student) => (
              <>
                <Row>
                  <Col
                    md={1}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="d-block">
                      <span className="fw-bold">{student.uid}</span>
                    </span>
                  </Col>
                  <Col md={6} className="d-flex align-items-center">
                    <span className="d-block">
                      <span className="fw-bold">{student.name}</span>
                    </span>
                  </Col>

                  <Col md={4}>
                    <InputGroup className=" d-flex flex-column">
                      <Form.Control
                        placeholder="Total mark..."
                        name="total"
                        type="number"
                        step={0.01}
                        required
                        className={`${styles.input} w-100`}
                        onChange={(e) =>
                          setStudents([
                            ...students.map((std) =>
                              student.id === std.id
                                ? {
                                    ...std,
                                    gained: parseFloat(e.target.value),
                                  }
                                : std
                            ),
                          ])
                        }
                      />
                    </InputGroup>
                  </Col>

                  <Col md={1} className="d-flex align-items-center">
                    <span
                      className={`text-danger fs-4  ${styles.del}`}
                      onClick={() => deleteHandeler(student.id)}
                    >
                      <BiTrash />
                    </span>
                  </Col>
                </Row>
                <hr />
              </>
            ))}
            <Row>
              <Col md={6}></Col>
            </Row>
            <Button
              onClick={submitHandeler}
              variant="primary"
              className="btn_primary"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  extra: state.week.extra,
});

export default connect(mapStateToProps, { getWeekStudentForBatch, createWeek })(
  AddWeeklyForm
);
