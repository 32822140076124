export const roundedPercentage = (p) => {
  let mark = parseFloat(p);
  if (isNaN(mark)) {
    return 0;
  }

  if ((mark * 10) % 10 === 0) {
    return mark.toFixed(0);
  }
  return (Math.round(mark * 10) / 10).toFixed(0);
};
