import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT,
  GET_STUDENT_DETAILS,
  UPDATE_STUDENT,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET STUDENT LIST
export const getStudentList =
  (year, gradeId, shiftId, batchId, roll) => async (dispatch) => {
    try {
      const data = {
        year,
        batchId,
        gradeId,
        shiftId,
        year,
        uid: roll,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${BASE_URL}/api/student/filter`,
        JSON.stringify(data),
        config
      );

      dispatch({
        type: GET_STUDENT,
        payload: res.data.data,
      });

      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
      return false;
    }
  };
//GET STUDENT Details
export const getStudentDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/student/${id}`);

    dispatch({
      type: GET_STUDENT_DETAILS,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE STUDENT
export const createStudent = (values) => async (dispatch) => {
  let formData = {
    name: values.name,
    year: values.year,
    phone: values.phone,
    uid: values.uid,
    shiftId: values.shiftId,
    batchId: values.batchId,
    gradeId: values.gradeId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/student`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: CREATE_STUDENT,
    });
    toast.success("Student added successfully");

    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE STUDENT
export const updateStudent = (values, id) => async (dispatch) => {
  let formData = {
    name: values.name,
    year: values.year,
    phone: values.phone,
    uid: values.uid,
    shiftId: values.shiftId,
    batchId: values.batchId,
    gradeId: values.gradeId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/student/${id}`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: UPDATE_STUDENT,
    });

    toast.success("Student updated successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Student
export const deleteStudent = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/student/${id}`);
    dispatch({
      type: DELETE_STUDENT,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
