import { combineReducers } from "redux";
import authReducer from "./Auth.reducer";
import batchReducer from "./Batch.reducer";
import gradeReducer from "./Grade.reducer";
import markReducer from "./Mark.reducer";
import shiftReducer from "./Shift.reducer";
import studentReducer from "./Student.reducer";
import weekReducer from "./Week.reducer";

const reducer = combineReducers({
  auth: authReducer,
  grade: gradeReducer,
  shift: shiftReducer,
  batch: batchReducer,
  student: studentReducer,
  mark: markReducer,
  week: weekReducer,
});

export default reducer;
