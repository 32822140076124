import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import { BiTrash } from "react-icons/bi";
import { getBatchStudent } from "../../actions/Batch.action";
import styles from "./AddMarkForm.module.css";
import { months, years } from "../../constants/MonthsAndYears";
import { toast } from "react-toastify";
import { createMark } from "../../actions/Mark.action";
import { useNavigate } from "react-router-dom";

const AddMarkForm = ({ edit, grades, getBatchStudent, createMark }) => {
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [base, setBase] = useState(0);
  const [working, setWorking] = useState(0);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [month, setMonth] = useState("0");
  const [loading, setLoading] = useState(false);

  const [students, setStudents] = useState(null);

  const navigate = useNavigate();

  const selectHandeler = async () => {
    const list = await getBatchStudent(batch, year);
    if (base === 0) {
      toast.error("Please input base number!");
    }
    setStudents(
      list.map((l) => ({
        id: l.id,
        studentId: l.id,
        uid: l.uid,
        name: l.name,
        phone: l.phone,
        total: 0,
        present: 0,
        base: parseInt(base),
        working_day: parseInt(working),
      }))
    );
  };

  const submitHandeler = async () => {
    setLoading(true);
    let check = await createMark(
      month,
      year,
      batch,
      students,
      working,
      grade,
      shift
    );
    if (check === true) {
      setLoading(false);
      navigate("/admin/mark");
    } else {
      setLoading(false);
    }
  };

  const deleteHandeler = (id) => {
    setStudents([...students.filter((st) => st.id !== id)]);
  };
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" && batch !== "" && base !== 0 ? (
              <Col
                md={3}
                className="d-flex jsutify-content-end align-items-end py-3"
              >
                <Button
                  onClick={selectHandeler}
                  variant="primary"
                  type="submit"
                  className="btn_primary"
                >
                  Select
                </Button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <InputGroup className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="base" className="d-block">
                    Base Mark
                  </label>
                </div>
                <Form.Control
                  placeholder="Base mark..."
                  name="base"
                  type="number"
                  required
                  className={`${styles.input} w-100`}
                  onChange={(e) => setBase(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md={3} className="py-3">
              <InputGroup className="mb-3 d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="working" className="d-block">
                    Working Day
                  </label>
                </div>
                <Form.Control
                  placeholder="Working day..."
                  name="working"
                  type="number"
                  required
                  className={`${styles.input} w-100`}
                  onChange={(e) => setWorking(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Month
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                {months.map((item, i) => (
                  <option key={i} value={`${i}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
                value={year}
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {students ? (
        <Card bg="white" text="dark" className={`crd shadow mt-4`}>
          <Card.Body>
            <h1 className="fs-4 fw-normal py-3">Fill the student marks</h1>
            <Row>
              <Col
                md={1}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="d-block fw-bold">Roll</span>
              </Col>
              <Col md={4} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Name</span>
              </Col>
              <Col md={3} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Total Mark</span>
              </Col>
              <Col md={3} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Present</span>
              </Col>
              <Col md={1} className="d-flex align-items-center ">
                <span className="d-block fw-bold">Delete</span>
              </Col>
            </Row>
            <hr />
            {students.map((student) => (
              <>
                <Row>
                  <Col
                    md={1}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="d-block">
                      <span className="fw-bold">{student.uid}</span>
                    </span>
                  </Col>
                  <Col md={4} className="d-flex align-items-center">
                    <span className="d-block">
                      <span className="fw-bold">{student.name}</span>
                    </span>
                  </Col>

                  <Col md={3}>
                    <InputGroup className=" d-flex flex-column">
                      <Form.Control
                        placeholder="Total mark..."
                        name="total"
                        type="number"
                        required
                        className={`${styles.input} w-100`}
                        onChange={(e) =>
                          setStudents([
                            ...students.map((std) =>
                              student.id === std.id
                                ? { ...std, total: parseInt(e.target.value) }
                                : std
                            ),
                          ])
                        }
                      />
                    </InputGroup>
                  </Col>

                  <Col md={3}>
                    <InputGroup className=" d-flex flex-column">
                      <Form.Control
                        placeholder="Present..."
                        name="present"
                        type="number"
                        required
                        className={`${styles.input} w-100`}
                        onChange={(e) =>
                          setStudents([
                            ...students.map((std) =>
                              student.id === std.id
                                ? { ...std, present: parseInt(e.target.value) }
                                : std
                            ),
                          ])
                        }
                      />
                    </InputGroup>
                  </Col>
                  <Col md={1} className="d-flex align-items-center">
                    <span
                      className={`text-danger fs-4  ${styles.del}`}
                      onClick={() => deleteHandeler(student.id)}
                    >
                      <BiTrash />
                    </span>
                  </Col>
                </Row>
                <hr />
              </>
            ))}
            <Row>
              <Col md={6}></Col>
            </Row>
            <Button
              onClick={submitHandeler}
              variant="primary"
              className="btn_primary"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default connect(null, { getBatchStudent, createMark })(AddMarkForm);
