import React from "react";
import { useNavigate } from "react-router-dom";

const PublicLayout = ({ children, title }) => {
  const navigate = useNavigate();
  return (
    <div className=" pt-5">
      {title ? <h1 className="text-center pb-2">{title}</h1> : <></>}
      <div className="d-flex justify-content-center align-items-center pb-4">
        <span className="feature__button px-4" onClick={() => navigate(-1)}>
          Back
        </span>
      </div>
      {children}
    </div>
  );
};

export default PublicLayout;
