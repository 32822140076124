import React from "react";
import { GradeList } from "../../components/GradeList";
import Layout from "../../components/shared/Layout/Layout";

const GradeListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Classes">
        <GradeList />
      </Layout>
    </div>
  );
};

export default GradeListPage;
