import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Spinner,
  Table,
  Col,
  Row,
  Form,
  Button,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import styles from "./StudentList.module.css";
import { connect } from "react-redux";
import { AiFillEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { useModals } from "@mantine/modals";
import { Text } from "@mantine/core";
import { toast } from "react-toastify";
import { deleteStudent, getStudentList } from "../../actions/Student.action";
import { getGradeList } from "../../actions/Grade.action";

const StudentList = ({
  list,
  getStudentList,
  deleteStudent,
  grades,
  getGradeList,
}) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState("");
  const modals = useModals();

  useEffect(() => {
    if (list === null) {
      getStudentList();
    }
    if (list !== null) {
      setRawList(list);
    }
    if (grades === null) {
      getGradeList();
    }
  }, [list]);

  const selectHandeler = async () => {
    await getStudentList(year, grade, shift, batch);
  };

  const deleteHandeler = (id) =>
    modals.openConfirmModal({
      title: "Delete this student",
      centered: true,
      children: (
        <Text size="md">
          Are you sure you want to delete this student? This action is
          destructive and you can not undo it.
        </Text>
      ),
      labels: { confirm: "Delete Student", cancel: "No don't delete it" },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => confirmHandeler(id),
    });

  const confirmHandeler = async (id) => {
    let check = await deleteStudent(id);
    if (check === true) {
      toast.success("Student deleted successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <Container className="pb-4">
      <div className="d-flex justify-content-end align-items-center pb-3 ">
        <div className="text-right">
          <Link to="/admin/student/add" className={styles.add}>
            <BsFileEarmarkPlus />
          </Link>
        </div>
      </div>

      <Card bg="white" text="dark" className={`crd shadow mb-4`}>
        <Card.Body>
          <Row>
            <Col md={6} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
              >
                <option value={""}>Select Year</option>
                {Array.from({ length: 20 }, (_, i) => i + 2010)
                  .reverse()
                  .map((item, i) => (
                    <option key={i} value={`${item}`}>
                      {item}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            <Col
              md={6}
              className="d-flex jsutify-content-end align-items-end py-3"
            >
              <Button
                onClick={selectHandeler}
                variant="primary"
                type="submit"
                className="btn_primary"
              >
                Select
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
      <Card className="crd p-md-4 pb-md-0 p-2">
        <Card.Body>
          {rawList === null ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: 600 }}
            >
              <Spinner variant="dark" animation="grow" />
            </div>
          ) : rawList.length === 0 ? (
            <span className="text-center fs-4 d-block pb-3">
              No Student Found
            </span>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Roll</th>
                    <th>Name</th>
                    <th>Year</th>
                    <th>Class</th>
                    <th>Shift</th>
                    <th>Batch</th>

                    <th>
                      <div className="d-flex justify-content-center align-items-center">
                        Actions
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rawList !== null &&
                    rawList
                      .sort(function (a, b) {
                        if (a.uid < b.uid) return -1;
                        if (a.uid > b.uid) return 1;
                        return 0;
                      })
                      .map((studentItem) => (
                        <tr key={studentItem.id}>
                          <td>{studentItem.uid}</td>
                          <td>
                            <Link
                              to={`/admin/mark/student/${studentItem.id}`}
                              className={`  me-4 ${styles.link}`}
                            >
                              {studentItem.name}
                            </Link>
                          </td>
                          <td>{studentItem.year}</td>
                          <td>{studentItem.grade.name}</td>
                          <td>{studentItem.shift.name}</td>
                          <td>{studentItem.batch.name}</td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <Link
                                to={`/admin/student/${studentItem.id}/edit`}
                                className={` fw-bold  me-4 ${styles.link}`}
                              >
                                <AiFillEdit />
                              </Link>
                              <span
                                onClick={() => deleteHandeler(studentItem.id)}
                                className={`fw-bold  ${styles.link}`}
                              >
                                <BsTrash />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.student.student,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getStudentList,
  deleteStudent,
  getGradeList,
})(StudentList);
