import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import {
  Button,
  Card,
  InputGroup,
  Form as BootstrapForm,
  Container,
} from "react-bootstrap";
import * as Yup from "yup";
import styles from "./AddBatchForm.module.css";
import { useNavigate } from "react-router-dom";
import { getGradeList } from "../../actions/Grade.action";
import { toast } from "react-toastify";
import { createBatch, updateBatch } from "../../actions/Batch.action";

const AddBatchForm = ({
  createBatch,
  data,
  edit,
  updateBatch,
  getGradeList,
  grades,
  gradeId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
  }, []);
  const onSubmitHandeler = async (values) => {
    if (values.gradeId === "none") {
      toast.error("Select a Class!");
      return false;
    }
    setIsLoading(true);
    let check = edit
      ? await updateBatch(values, data.id)
      : await createBatch(values);
    if (check === true) {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/admin/batch");
      }, 1000);
    } else {
      setIsLoading(false);
    }
  };

  let initVals = {
    name: edit === true && data ? data.name : "",
    year: edit === true && data ? data.year : "",
    gradeId: edit === true && data && gradeId !== -1 ? gradeId : "none",
    shiftId: edit === true && data ? data.shiftId : "none",
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("Batch Name is required!"),
    year: Yup.string().required("Batch Year is required!"),
    gradeId: Yup.string().required("Class is required!"),
    shiftId: Yup.string().required("Shift is required!"),
  });
  return (
    <Container>
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <h1 className="fs-4 fw-normal py-3">
            Fill the form to {edit ? "edit" : "add"} batch
          </h1>
          <Formik
            initialValues={initVals}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={(values) => onSubmitHandeler(values)}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="name" className="d-block">
                      Name
                    </label>
                    {errors.name && touched.name ? (
                      <small className="text-danger pt-2">{errors.name}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type name of the shift..."
                    name="name"
                    isValid={!errors.name && touched.name}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.name && touched.name}
                  />
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center ">
                    <label htmlFor="year" className="d-block">
                      Batch Year
                    </label>
                    {errors.year && touched.year ? (
                      <small className="text-danger pt-2">{errors.year}</small>
                    ) : null}
                  </div>
                  <Field
                    as={BootstrapForm.Control}
                    placeholder="Type year of the batch..."
                    name="year"
                    isValid={!errors.year && touched.year}
                    type="text"
                    className={`${styles.input} w-100`}
                    isInvalid={errors.year && touched.year}
                  />
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="gradeId" className="d-block">
                      Class
                    </label>
                    {errors.gradeId && touched.gradeId ? (
                      <small className="text-danger pt-2">
                        {errors.gradeId}
                      </small>
                    ) : null}
                  </div>
                  {grades ? (
                    <BootstrapForm.Select
                      gradeId="gradeId"
                      isValid={!errors.gradeId && touched.gradeId}
                      className={`${styles.input} w-100`}
                      isInvalid={errors.gradeId && touched.gradeId}
                      onChange={(e) => setFieldValue("gradeId", e.target.value)}
                      value={values.gradeId}
                    >
                      <option value="none"> Select Class</option>
                      {grades.map((grd) => (
                        <option value={grd.id} key={grd.id}>
                          {grd.name}
                        </option>
                      ))}
                    </BootstrapForm.Select>
                  ) : (
                    <></>
                  )}
                </InputGroup>
                {values.gradeId && values.gradeId !== "none" ? (
                  <InputGroup className="mb-3 d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <label htmlFor="shiftId" className="d-block">
                        Shift
                      </label>
                      {errors.shiftId && touched.shiftId ? (
                        <small className="text-danger pt-2">
                          {errors.shiftId}
                        </small>
                      ) : null}
                    </div>
                    {grades ? (
                      <BootstrapForm.Select
                        id="shiftId"
                        isValid={!errors.shiftId && touched.shiftId}
                        className={`${styles.input} w-100`}
                        isInvalid={errors.shiftId && touched.shiftId}
                        onChange={(e) =>
                          setFieldValue("shiftId", e.target.value)
                        }
                        value={values.shiftId}
                      >
                        <option value="none"> Select Shift</option>
                        {grades
                          .filter((it) => it.id === parseInt(values.gradeId))[0]
                          .shifts?.map((grd) => (
                            <option value={grd.id} key={grd.id}>
                              {grd.name}
                            </option>
                          ))}
                      </BootstrapForm.Select>
                    ) : (
                      <></>
                    )}
                  </InputGroup>
                ) : (
                  <></>
                )}

                <div className="pt-4">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn_primary"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "Loading..."
                      : edit
                      ? "Edit Batch"
                      : "Add Batch"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  createBatch,
  updateBatch,
  getGradeList,
})(AddBatchForm);
