import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_SHIFT,
  DELETE_SHIFT,
  GET_SHIFT,
  UPDATE_SHIFT,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";
import { getGradeList } from "./Grade.action";

//GET SHIFT LIST
export const getShiftList = () => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/shift`);
    // console.log(res);

    dispatch({
      type: GET_SHIFT,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE SHIFT
export const createShift = (values) => async (dispatch) => {
  let formData = {
    name: values.name,
    gradeId: values.gradeId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/shift`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: CREATE_SHIFT,
      payload: res.data.data,
    });
    toast.success("Shift added successfully");

    dispatch(getGradeList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE SHIFT
export const updateShift = (values, id) => async (dispatch) => {
  let formData = {
    name: values.name,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/shift/${id}`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: UPDATE_SHIFT,
      payload: res.data.data,
    });
    toast.success("Shift updated successfully");
    dispatch(getGradeList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Shift
export const deleteShift = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/shift/${id}`);
    dispatch({
      type: DELETE_SHIFT,
      payload: id,
    });
    dispatch(getGradeList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
