import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getGradeList } from "../../actions/Grade.action";
import EditMarkForm from "../../components/EditMarkForm/EditMarkForm";
import Layout from "../../components/shared/Layout/Layout";

const EditMarkPage = ({ edit, grades, getGradeList, mark }) => {
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <Layout title={`Edit Result`}>
        {grades ? (
          <EditMarkForm edit={true} grades={grades} mark={mark} />
        ) : (
          <h3>Loading...</h3>
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(EditMarkPage);
