import workflowImg from "../../../Assets/Features/Workflow.svg";
import flexibilityImg from "../../../Assets/Features/Flexibility.svg";
import userImg from "../../../Assets/Features/User.svg";
import layoutImg from "../../../Assets/Features/Layout.svg";
import controlImg from "../../../Assets/Features/Control.svg";
import organizedImg from "../../../Assets/Features/Organized.svg";

const data = [
  {
    id: 5,
    title: "Individual Result",
    description: "Watch your individual result",
    image: controlImg,
    link: "/individual-result",
  },
  {
    id: 6,
    title: "View Result",
    description: "View and find the result of the whole batch",
    image: organizedImg,
    link: "/view-result",
  },
  {
    id: 1,
    title: "Merit List",
    description: "Viiew the monthly merit list of all students",
    image: workflowImg,
    link: "/merit-list",
  },
  // {},
  // {
  //   id: 2,
  //   title: "Compare",
  //   description: "Compare alarming students of batch",
  //   link: "/compare",
  //   image: flexibilityImg,
  // },
  // {
  //   id: 3,
  //   title: "Academic Graph",
  //   description: "Visualize overall result of a batch",
  //   link: "/academic-graph",
  //   image: layoutImg,
  // },
];

export default data;
