import React from "react";
import ClassSummary from "../../components/ClassSummary/ClassSummary";
import Layout from "../../components/shared/Layout/Layout";

const ClassSummaryPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Class Summary`}>
        <ClassSummary />
      </Layout>
    </div>
  );
};

export default ClassSummaryPage;
