import React from "react";
// import styles from "./PiChartComparison.module.css";
import Chart from "react-apexcharts";
import useWindowDimensions from "../../hooks/windowDimensions";

const PiChartComparison = ({ app, ap, am, a, b, c }) => {
  const colors = ["#34eb4c", "#eb3434"];
  const { width } = useWindowDimensions();
  const options = {
    options: {
      labels: ["Good Student", "Alarming Student"],
      legend: {
        show: true,
        position: "bottom",
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          useSeriesColors: false,
          fillColors: colors,
        },
      },
      fill: {
        colors: colors,
        useSeriesColors: false,
      },
      markers: {
        colors: colors,
      },
      dataLabels: {
        enabled: true,
      },
      pie: {
        donut: {
          labels: {
            show: true,
            name: ["Normal Student", "Alarming Student"],
            value: [app, ap, a, am, b, c],
          },
        },
      },
    },
    series: [app + ap + a, am + b + c],
  };

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <Chart
        options={options.options}
        series={options.series}
        type="donut"
        width={width < 767 ? "300px" : "700px"}
      />
    </div>
  );
};

export default PiChartComparison;
