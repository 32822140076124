import {
  GET_ALL_WEEKLY,
  GET_STUDENT_WEEK,
  GET_WEEK,
  GET_WEEK_BATCH,
} from "../constants/Type";

const initialState = {
  week: null,
  students: null,
  extra: null,
  loading: true,
  results: null,
  all: null,
};

const weekReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_WEEK:
      return {
        ...state,
        week: payload.data,
        extra: payload.extra,
        loading: false,
      };
    case GET_ALL_WEEKLY:
      return {
        ...state,
        all: payload.data,
        extra: payload.extra,
        loading: false,
      };
    case GET_WEEK_BATCH:
      return {
        ...state,
        results: payload.data,
        extra: payload.extra,
        loading: false,
      };
    case GET_STUDENT_WEEK:
      return {
        ...state,
        students: { ...payload.data },
        extra: { ...payload.extra },
        loading: false,
      };

    default:
      return state;
  }
};

export default weekReducer;
