import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import ViewResults from "../../components/ViewResults/ViewResults";

const ViewResultPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`View Result`}>
        <ViewResults />
      </Layout>
    </div>
  );
};

export default ViewResultPage;
