import React from "react";

import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";
import { TopMarkList } from "../../components/TopMarkList";

const PublicMarkListPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title={` Result`}>
        <TopMarkList />
      </PublicLayout>
    </div>
  );
};

export default PublicMarkListPage;
