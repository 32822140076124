import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import { WeeklyViewResults } from "../../components/WeeklyViewResults";

const WeeklyViewAllPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`View Weekly Result`}>
        <WeeklyViewResults />
      </Layout>
    </div>
  );
};

export default WeeklyViewAllPage;
