import React from "react";
//import styles from "./lineChart.module.css";
import Chart from "react-apexcharts";
import { months } from "../../constants/MonthsAndYears";

const LineChart = ({ data }) => {
  const vals = {
    months: [],
    marks: [],
  };

  const options = {
    dropShadow: {
      enabled: true,
      top: 0,
      left: 0,
      blur: 3,
      opacity: 0.5,
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#3c9cf6",
        shadeTo: "dark",
        shadeIntensity: 0.65,
      },
    },
  };

  if (data) {
    for (let i = 0; i < data.length; i++) {
      vals.months.push(`${months[data[i].month]} - ${data[i].year}`);
      vals.marks.push(Math.round(data[i].percent));
    }
  }

  return (
    <div>
      <Chart
        options={{
          chart: {
            id: "running-order-sell",
          },
          xaxis: {
            categories: vals.months.reverse(),
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 10,
          },
          ...options,
        }}
        height={350}
        series={[
          {
            name: "Mark (%)",
            data: vals.marks.reverse(),
          },
        ]}
        type="bar"
      />
    </div>
  );
};

export default LineChart;
