import React from "react";
import { IndividualResultForm } from "../../components/IndividualResultForm";
import PublicLayout from "../../components/shared/PublicLayout/PublicLayout";

const PublicIndividualResult = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <PublicLayout title="Individual Result">
        <IndividualResultForm hide={true} />
      </PublicLayout>
    </div>
  );
};

export default PublicIndividualResult;
