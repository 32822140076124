import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import styles from "./ClassPerformanceClass.module.css";
import { connect } from "react-redux";
import { AiFillPrinter } from "react-icons/ai";
import { getGradeList } from "../../actions/Grade.action";
import { months, years } from "../../constants/MonthsAndYears";
import { getMarkListForPerformanceGrade } from "../../actions/Mark.action";
import { getGrade } from "../../utils/getGrade";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import SectionHeading from "../SectionHeading/SectionHeading";
import { roundedPercentage } from "../../utils/roundedPercentage";

const ClassPerformanceClass = ({
  list,
  getGradeList,
  grades,
  getMarkListForPerformanceGrade,
}) => {
  const [rawList, setRawList] = useState(list === null ? [] : list);
  const [grade, setGrade] = useState("");
  const [batch, setBatch] = useState("");
  const [shift, setShift] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("0");

  const ref = useRef(null);

  useEffect(() => {
    if (grades === null) {
      getGradeList();
    }
    if (list !== null) {
      setRawList(list);
    }
  }, [list]);

  const selectHandeler = async () => {
    await getMarkListForPerformanceGrade(year, month, grade, shift, batch);
  };

  const getClass = (data) => {
    let classes = [];
    for (let i = 0; i < data.length; i++) {
      if (classes.filter((cls) => cls.id === data[i].gradeId).length === 0) {
        classes.push({
          id: data[i].gradeId,
          grade: data[i].grade.name,
        });
      }
    }
    return { classes };
  };
  const getBatches = (data) => {
    let batches = [];
    for (let i = 0; i < data.length; i++) {
      if (batches.filter((cls) => cls.id === data[i].batchId).length === 0) {
        batches.push({
          id: data[i].batchId,
          batch: data[i].batch.name,
          shift: data[i].shift.name,
        });
      }
    }
    return { batches };
  };
  const getBatchesPercent = (data, gradeId, status) => {
    let count = 0;
    data
      .filter((item) =>
        gradeId === null
          ? getGrade(item.percent) === status
          : item.gradeId === gradeId && getGrade(item.percent) === status
      )
      .map((l) => count++);
    return roundedPercentage(
      (count * 100) /
        data.filter((item) =>
          gradeId === null ? true : item.gradeId === gradeId
        ).length
    );
  };

  return (
    <Container className="pb-4">
      <Card bg="white" text="dark" className={`crd shadow`}>
        <Card.Body>
          <Row>
            <Col md={3} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="grade" className="d-block">
                  Class
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setBatch("");
                  setShift("");
                  setGrade(e.target.value);
                }}
              >
                <option value={""}>Select Class</option>
                {grades &&
                  grades.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            {grades && grade !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="shift" className="d-block">
                    Shift
                  </label>
                </div>
                <Form.Select
                  onChange={(e) => {
                    setBatch("");
                    setShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .shifts.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
            {grade !== "" && shift !== "" ? (
              <Col md={3} className="py-3">
                <div className="d-flex justify-content-between align-items-center pb-2">
                  <label htmlFor="batch" className="d-block">
                    Batch
                  </label>
                </div>
                <Form.Select onChange={(e) => setBatch(e.target.value)}>
                  <option value={""}>Select Batch</option>
                  {grades
                    .filter((grd) => grd.id === parseInt(grade))[0]
                    .batches.filter((bt) => bt.shiftId === parseInt(shift))
                    .map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="month" className="d-block">
                  Month
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
                id="month"
                name="month"
              >
                {months.map((item, i) => (
                  <option key={i} value={`${i}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={4} className="py-3">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <label htmlFor="year" className="d-block">
                  Year
                </label>
              </div>
              <Form.Select
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                id="year"
                name="year"
                value={year}
              >
                {years.map((item, i) => (
                  <option key={i} value={`${item}`}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col
              md={3}
              className="d-flex jsutify-content-end align-items-end py-3"
            >
              <Button
                onClick={selectHandeler}
                variant="primary"
                type="submit"
                className="btn_primary"
              >
                Select
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {rawList !== null ? (
        <Card className="crd p-md-2 pb-md-0 p-2 mt-4">
          <Card.Body>
            {rawList === null ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: 600 }}
              >
                <Spinner variant="dark" animation="grow" />
              </div>
            ) : rawList.length === 0 ? (
              <span className="text-center fs-4 d-block pb-3">
                No Mark Found
              </span>
            ) : (
              <div ref={ref} className={styles.print}>
                <div className="d-flex justify-content-end ">
                  <div className="">
                    <ReactToPrint
                      documentTitle={`Marksheet - ${rawList[0].batch.name} - ${
                        months[rawList[0].month]
                      } - ${rawList[0].year}`}
                      content={() => ref.current}
                    >
                      <PrintContextConsumer>
                        {({ handlePrint }) => (
                          <span
                            className={styles.chart_icon}
                            onClick={handlePrint}
                          >
                            <AiFillPrinter />
                          </span>
                        )}
                      </PrintContextConsumer>
                    </ReactToPrint>
                  </div>
                </div>
                <div className="px-3">
                  <SectionHeading
                    title={"Monthly Class Performance Report"}
                    month={months[rawList[0]?.month]}
                    year={rawList[0]?.year}
                    customClass={styles.heading}
                  />
                  {/* <span className="text-center fs-4 d-block ">
                    Class Performance Report
                  </span>
                  <span className="text-center fs-6 fw-bold d-block">
                    {rawList !== null
                      ? ` ${months[rawList[0].month]} - ${rawList[0].year}`
                      : ""}
                  </span> */}

                  <div className="d-flex align-items center justify-content-center">
                    <div className="d-flex justify-content-center ">
                      <span className="d-block pe-3">
                        <span className="fw-bold">A++</span> = (91-100)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A+</span> = (81-90)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A</span> = (71-80)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">A-</span> = (61-70)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">B</span> = (51-60)
                      </span>
                      <span className="d-block pe-3">
                        <span className="fw-bold">C</span> = {"(<51)"}{" "}
                      </span>
                    </div>
                  </div>
                  <div className={styles.tbl_wrapper}>
                    {rawList !== null &&
                      getClass(rawList).classes.map((markItem, i) => (
                        <div key={markItem.id}>
                          <span
                            className={`d-block  fw-bold pt-2 pb-2 ${styles.print_heading}`}
                          >
                            Class: {markItem.grade}
                          </span>

                          <Table
                            bordered
                            responsive
                            striped
                            className="mb-2"
                            size="sm"
                          >
                            <thead>
                              <tr>
                                <th>Shift</th>
                                <th>Batch</th>
                                <th>Total Student</th>
                                <th>A++</th>
                                <th>A+ </th>
                                <th>A </th>
                                <th>A- </th>
                                <th>B </th>
                                <th>C </th>
                              </tr>
                            </thead>
                            <tbody>
                              {getBatches(
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                )
                              ).batches.map((btch) => (
                                <tr key={btch.id}>
                                  <td>{btch.shift}</td>
                                  <td>{btch.batch}</td>
                                  <td>
                                    {
                                      rawList.filter(
                                        (rw) => rw.batchId === btch.id
                                      ).length
                                    }
                                  </td>

                                  <td>
                                    {
                                      rawList
                                        .filter((rw) => rw.batchId === btch.id)
                                        .filter(
                                          (l) => getGrade(l.percent) === "A++"
                                        ).length
                                    }
                                  </td>
                                  <td>
                                    {
                                      rawList
                                        .filter((rw) => rw.batchId === btch.id)
                                        .filter(
                                          (l) => getGrade(l.percent) === "A+"
                                        ).length
                                    }
                                  </td>
                                  <td>
                                    {
                                      rawList
                                        .filter((rw) => rw.batchId === btch.id)
                                        .filter(
                                          (l) => getGrade(l.percent) === "A"
                                        ).length
                                    }
                                  </td>
                                  <td>
                                    {
                                      rawList
                                        .filter((rw) => rw.batchId === btch.id)
                                        .filter(
                                          (l) => getGrade(l.percent) === "A-"
                                        ).length
                                    }
                                  </td>
                                  <td>
                                    {
                                      rawList
                                        .filter((rw) => rw.batchId === btch.id)
                                        .filter(
                                          (l) => getGrade(l.percent) === "B"
                                        ).length
                                    }
                                  </td>
                                  <td>
                                    {
                                      rawList
                                        .filter((rw) => rw.batchId === btch.id)
                                        .filter(
                                          (l) => getGrade(l.percent) === "C"
                                        ).length
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="d-flex align-items-center justify-content-center flex-column">
                            <div className="d-flex ">
                              <span className="d-block pe-3">
                                <span className="fw-bold">A++</span> ={" "}
                                {
                                  rawList
                                    .filter((rw) => rw.gradeId === markItem.id)
                                    .filter(
                                      (l) => getGrade(l.percent) === "A++"
                                    ).length
                                }
                              </span>
                              <span className="d-block pe-3">
                                <span className="fw-bold">A+</span> ={" "}
                                {
                                  rawList
                                    .filter((rw) => rw.gradeId === markItem.id)
                                    .filter((l) => getGrade(l.percent) === "A+")
                                    .length
                                }
                              </span>
                              <span className="d-block pe-3">
                                <span className="fw-bold">A</span> ={" "}
                                {
                                  rawList
                                    .filter((rw) => rw.gradeId === markItem.id)
                                    .filter((l) => getGrade(l.percent) === "A")
                                    .length
                                }
                              </span>
                              <span className="d-block pe-3">
                                <span className="fw-bold">A-</span> ={" "}
                                {
                                  rawList
                                    .filter((rw) => rw.gradeId === markItem.id)
                                    .filter((l) => getGrade(l.percent) === "A-")
                                    .length
                                }
                              </span>
                              <span className="d-block pe-3">
                                <span className="fw-bold">B</span> ={" "}
                                {
                                  rawList
                                    .filter((rw) => rw.gradeId === markItem.id)
                                    .filter((l) => getGrade(l.percent) === "B")
                                    .length
                                }
                              </span>
                              <span className="d-block pe-3">
                                <span className="fw-bold">C</span> ={" "}
                                {
                                  rawList
                                    .filter((rw) => rw.gradeId === markItem.id)
                                    .filter((l) => getGrade(l.percent) === "C")
                                    .length
                                }
                              </span>
                            </div>
                            {/* <div className="pt-3">
                            Total Students:{" "}
                            <span className="fw-bold">
                              {
                                rawList.filter(
                                  (rw) => rw.gradeId === markItem.id
                                ).length
                              }
                            </span>
                          </div> */}
                          </div>
                          <Table
                            bordered
                            responsive
                            striped
                            className={`${styles.print_table} mt-2 mb-2`}
                            size="sm"
                          >
                            <thead>
                              <tr>
                                <th>Total Student</th>
                                <th>A++</th>
                                <th>A+ </th>
                                <th>A </th>
                                <th>A- </th>
                                <th>B </th>
                                <th>C </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {
                                    rawList.filter(
                                      (rw) => rw.gradeId === markItem.id
                                    ).length
                                  }
                                </td>
                                <td>
                                  {getBatchesPercent(
                                    rawList,
                                    markItem.id,
                                    "A++"
                                  )}
                                  %
                                </td>
                                <td>
                                  {getBatchesPercent(
                                    rawList,
                                    markItem.id,
                                    "A+"
                                  )}
                                  %
                                </td>
                                <td>
                                  {getBatchesPercent(rawList, markItem.id, "A")}
                                  %
                                </td>
                                <td>
                                  {getBatchesPercent(
                                    rawList,
                                    markItem.id,
                                    "A-"
                                  )}
                                  %
                                </td>
                                <td>
                                  {getBatchesPercent(rawList, markItem.id, "B")}
                                  %
                                </td>
                                <td>
                                  {getBatchesPercent(rawList, markItem.id, "C")}
                                  %
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      ))}

                    <div className="">
                      <span
                        className={`d-block fs-4 fw-bold pt-2 pb-2 ${styles.print_heading}`}
                      >
                        Total Summary
                      </span>
                      <Table
                        bordered
                        responsive
                        striped
                        className={`${styles.print_table} ${styles.print_table_total} mt-2 mb-3`}
                        size="sm"
                      >
                        <thead>
                          <tr>
                            <th>Total Student</th>
                            <th>A++</th>
                            <th>A+ </th>
                            <th>A </th>
                            <th>A- </th>
                            <th>B </th>
                            <th>C </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{rawList.length}</td>
                            <td>{getBatchesPercent(rawList, null, "A++")}%</td>
                            <td>{getBatchesPercent(rawList, null, "A+")}%</td>
                            <td>{getBatchesPercent(rawList, null, "A")}%</td>
                            <td>{getBatchesPercent(rawList, null, "A-")}%</td>
                            <td>{getBatchesPercent(rawList, null, "B")}%</td>
                            <td>{getBatchesPercent(rawList, null, "C")}%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      ) : (
        <></>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.mark.mark,
  grades: state.grade.grade,
});

export default connect(mapStateToProps, {
  getGradeList,
  getMarkListForPerformanceGrade,
})(ClassPerformanceClass);
