import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_WEEK,
  GET_ALL_WEEKLY,
  GET_STUDENT_WEEK,
  GET_WEEK,
  GET_WEEK_BATCH,
  UPDATE_WEEK,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET MARK LIST
export const getAllWeeklyResult =
  (batchId, year, week, gradeId, shiftId) => async (dispatch) => {
    const data = {
      batchId,
      year,
      week,
      gradeId,
      shiftId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/week/all`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_ALL_WEEKLY,
        payload: { ...res.data },
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

//GET WEEK MARK LIST
export const getWeekResult =
  (start, end, studentId, year, gradeId, shiftId, batchId) =>
  async (dispatch) => {
    const data = {
      start,
      end,
      year,
      gradeId,
      shiftId,
      batchId,
      studentId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/week/between`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_WEEK,
        payload: { ...res.data },
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
//GET GRADE MARK LIST
export const getWeekStudentForBatch =
  (year, week, batchId) => async (dispatch) => {
    const data = {
      year,
      week,
      batchId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/week/batch`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_STUDENT_WEEK,
        payload: { ...res.data },
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
//GET GRADE MARK LIST
export const getBatchWeeklyResult =
  (year, week, batchId) => async (dispatch) => {
    const data = {
      year,
      week,
      batchId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/api/week/batch-res`,
        JSON.stringify(data),
        config
      );
      // console.log(res);

      dispatch({
        type: GET_WEEK_BATCH,
        payload: { ...res.data },
      });
      return res.data.data;
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

// CREATE MARK
export const createWeek = (week, marks, year) => async (dispatch) => {
  let data = {
    week: parseInt(week),
    marks,
    year,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/week`,
      JSON.stringify(data),
      config
    );
    dispatch({
      type: CREATE_WEEK,
    });
    toast.success("Weekly result added successfully");

    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
// CREATE MARK
export const editWeek = (week, marks, year) => async (dispatch) => {
  let data = {
    week: parseInt(week),
    marks,
    year,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/week`,
      JSON.stringify(data),
      config
    );
    dispatch({
      type: UPDATE_WEEK,
    });
    toast.success("Weekly result edited successfully");

    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
