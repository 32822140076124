import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getBatchList } from "../../actions/Batch.action";
import AddBatchForm from "../../components/AddBatchForm/AddBatchForm";
import Layout from "../../components/shared/Layout/Layout";

const AddBatchPage = ({ edit, batch, getBatchList }) => {
  const { id } = useParams();
  useEffect(() => {
    if (edit && id && !batch) {
      getBatchList();
    }
  }, [id]);

  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Batch`}>
        {edit && id && batch ? (
          <AddBatchForm
            edit={true}
            data={batch.filter((item) => item.id === parseInt(id))[0]}
            gradeId={
              batch.filter((item) => item.id === parseInt(id))[0].gradeId
            }
          />
        ) : (
          <AddBatchForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  batch: state.batch.batch,
});

export default connect(mapStateToProps, { getBatchList })(AddBatchPage);
