import React from "react";
import { useParams } from "react-router-dom";
import { MarkListStudent } from "../../components/MarkListStudent";
import Layout from "../../components/shared/Layout/Layout";

const MarkListStudentPage = () => {
  const { id } = useParams();
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title="Student Mark">
        <MarkListStudent id={id} />
      </Layout>
    </div>
  );
};

export default MarkListStudentPage;
