import { Routes, Route, BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AddMarkPage, DashboardPage, LoginPage, MarkListPage } from "./views";
import { ToastContainer } from "react-toastify";
import PrivateOutlet from "./utils/PrivateOutlet";
import { useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import setAuthToken from "./utils/setAuthToken";
import AddGradePage from "./views/AddGradePage/AddGradePage";
import GradeListPage from "./views/GradeListPage/GradeListPage";
import ShiftListPage from "./views/ShiftListPage/ShiftListPage";
import AddShiftPage from "./views/AddShiftPage/AddShiftPage";
import BatchListPage from "./views/BatchListPage/BatchListPage";
import AddBatchPage from "./views/AddBatchPage/AddBatchPage";
import StudentListPage from "./views/StudentListPage/StudentListPage";
import AddStudentPage from "./views/AddStudentPage/AddStudentPage";
import MarkListStudentPage from "./views/MarkListStudentPage/MarkListStudentPage";
import EditMarkPage from "./views/EditMarkPage/EditMarkPage";
import ViewResultPage from "./views/ViewResultPage/ViewResultPage";
import AlarmingStudentPage from "./views/AlarmingStudentPage/AlarmingStudentPage";
import AcademicGraphPage from "./views/AcademicGraphPage/AcademicGraphPage";
import CompariosnGraph from "./views/CompariosnGraph/CompariosnGraph";
import IndividualResult from "./views/IndividualResult/IndividualResult";
import LandingPage from "./views/LandingPage/LandingPage";
import PublicIndividualResult from "./views/PublicIndividualResult/IndividualResult";
import PublicMarkListStudentPage from "./views/PublicMarkListStudentPage/PublicMarkListStudentPage";
import PublicMarkListPage from "./views/PublicMarkListPage/PublicMarkListPage";
import PublicAlarmingStudentPage from "./views/PublicAlarmingStudentPage/PublicAlarmingStudentPage";
import PublicCompariosnGraphPage from "./views/PublicCompariosnGraphPage/PublicCompariosnGraphPage";
import PublicAcademicGraphPage from "./views/PublicAcademicGraphPage/PublicAcademicGraphPage";
import ClassPerformancePage from "./views/ClassPerformancePage/ClassPerformancePage";
import ClassSummaryPage from "./views/ClassSummaryPage/ClassSummaryPage";
import PublicTopResult from "./views/PublicTopResult/PublicTopResult";
import AddWeeklyPage from "./views/AddWeeklyPage/AddWeeklyPage";
import WeeklyViewResultPage from "./views/WeeklyViewResultPage/WeeklyViewResultPage";
import EditWeeklyPage from "./views/EditWeeklyPage/EditWeeklyPage";
import WeeklyViewAllPage from "./views/WeeklyViewAllPage/WeeklyViewAllPage";
import WeeklyTopResultPage from "./views/WeeklyTopResultPage/WeeklyTopResultPage";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("token_coaching")) {
      setAuthToken(localStorage.getItem("token_coaching"));
    }
    loadingHandeler();

    return () => {
      setLoading(false);
    };
  }, [loading]);

  const loadingHandeler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };
  return (
    <>
      {loading ? (
        <div className={`landing_loader`}>
          <span className="d-block text-light fs-4 py-4">Loading...</span>
          <ScaleLoader color={"#f68a3c"} loading={loading} size={150} />
        </div>
      ) : (
        <></>
      )}
      <ToastContainer newestOnTop theme="dark" />
      <BrowserRouter>
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/view-result" element={<PublicMarkListPage />} />
          <Route
            path="/alarming-students"
            element={<PublicAlarmingStudentPage />}
          />
          <Route path="/academic-graph" element={<PublicAcademicGraphPage />} />
          <Route path="/compare" element={<PublicCompariosnGraphPage />} />
          <Route path="/merit-list" element={<PublicTopResult />} />
          <Route
            path="/individual-result"
            element={<PublicIndividualResult />}
          />
          <Route path="/student/:id" element={<PublicMarkListStudentPage />} />

          <Route path="/*" element={<PrivateOutlet />}>
            <>
              {/* PRIVATE ROUTES */}
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="grade/add" element={<AddGradePage />} />
              <Route
                path="admin/class-performance/"
                element={<ClassPerformancePage />}
              />
              <Route
                path="admin/class-summary"
                element={<ClassSummaryPage />}
              />
              <Route
                path="admin/class-performance/:type"
                element={<ClassPerformancePage />}
              />
              <Route
                path="grade/:id/edit"
                element={<AddGradePage edit={true} />}
              />
              <Route path="grade" element={<GradeListPage />} />
              <Route path="admin/shift" element={<ShiftListPage />} />
              <Route path="admin/shift/add" element={<AddShiftPage />} />
              <Route
                path="admin/shift/:id/edit"
                element={<AddShiftPage edit={true} />}
              />
              <Route path="admin/batch" element={<BatchListPage />} />
              <Route path="admin/batch/add" element={<AddBatchPage />} />
              <Route
                path="admin/batch/:id/edit"
                element={<AddBatchPage edit={true} />}
              />
              <Route path="admin/week/add" element={<AddWeeklyPage />} />
              <Route path="admin/week/view" element={<WeeklyViewAllPage />} />
              <Route path="admin/week/edit" element={<EditWeeklyPage />} />
              <Route
                path="admin/week/result"
                element={<WeeklyViewResultPage />}
              />
              <Route path="admin/week/top" element={<WeeklyTopResultPage />} />
              <Route path="admin/mark/add" element={<AddMarkPage />} />
              <Route path="admin/mark/edit" element={<EditMarkPage />} />
              <Route
                path="admin/mark/student/:id"
                element={<MarkListStudentPage />}
              />

              <Route
                path="admin/mark/top"
                element={<MarkListPage top={true} />}
              />
              <Route path="admin/comparison" element={<CompariosnGraph />} />
              <Route path="admin/mark" element={<MarkListPage />} />
              <Route path="admin/view-result" element={<ViewResultPage />} />
              <Route
                path="admin/academic-graph"
                element={<AcademicGraphPage />}
              />
              <Route
                path="admin/individual-result"
                element={<IndividualResult />}
              />
              <Route
                path="admin/alarming-student"
                element={<AlarmingStudentPage />}
              />
              <Route path="admin/student" element={<StudentListPage />} />
              <Route path="admin/student/add" element={<AddStudentPage />} />
              <Route
                path="admin/student/:id/edit"
                element={<AddStudentPage edit={true} />}
              />
            </>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
