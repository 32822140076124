import {
  CREATE_MARK,
  DELETE_MARK,
  GET_BATCH_STUDENTS,
  GET_MARK,
  GET_MARK_DETAILS,
  GET_STUDENT_MARK,
  UPDATE_MARK,
} from "../constants/Type";

const initialState = {
  mark: null,
  extra: null,
  student_mark: null,
  students: null,
  selected_mark: null,
  loading: true,
};

const markReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MARK:
      return {
        ...state,
        mark: payload.data,
        extra: payload.extra,
        loading: false,
      };
    case GET_STUDENT_MARK:
      return {
        ...state,
        student_mark: payload,
        loading: false,
      };
    case GET_BATCH_STUDENTS:
      return {
        ...state,
        students: payload,
        loading: false,
      };
    case GET_MARK_DETAILS:
      return {
        ...state,
        selected_mark: payload,
        loading: false,
      };

    case DELETE_MARK:
      return {
        ...state,
        mark: state.mark.filter((grd) => grd.id !== parseInt(payload)),
        loading: false,
      };

    case UPDATE_MARK:
      return {
        ...state,
        mark: state.mark
          ? state.mark.map((grd) => (grd.id === payload.id ? payload : grd))
          : null,
        loading: false,
      };

    default:
      return state;
  }
};

export default markReducer;
