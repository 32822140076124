import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getStudentDetails,
  getStudentList,
} from "../../actions/Student.action";
import AddStudentForm from "../../components/AddStudentForm/AddStudentForm";
import Layout from "../../components/shared/Layout/Layout";

const AddStudentPage = ({ edit, student, getStudentDetails }) => {
  const { id } = useParams();
  useEffect(() => {
    if (edit && id && !student) {
      getStudentDetails(id);
    }
  }, [id]);

  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Student`}>
        {edit && id && student ? (
          <AddStudentForm edit={true} data={student} />
        ) : (
          <AddStudentForm />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  student: state.student.selected_student,
});

export default connect(mapStateToProps, { getStudentDetails })(AddStudentPage);
