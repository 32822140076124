import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getGradeList } from "../../actions/Grade.action";
import AddWeeklyForm from "../../components/AddWeeklyForm/AddWeeklyForm";
import Layout from "../../components/shared/Layout/Layout";

const AddWeeklyPage = ({ edit, grades, getGradeList }) => {
  const { id } = useParams();
  useEffect(() => {
    getGradeList();
  }, []);
  return (
    <div className="bg_light">
      <Layout title={`${edit ? "Edit" : "Add"} Weekly Result`}>
        {edit && id && grades ? (
          <AddWeeklyForm grades={grades} />
        ) : (
          <AddWeeklyForm grades={grades ? grades : []} />
        )}
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  grades: state.grade.grade,
});

export default connect(mapStateToProps, { getGradeList })(AddWeeklyPage);
