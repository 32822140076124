import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_GRADE,
  DELETE_GRADE,
  GET_GRADE,
  UPDATE_GRADE,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET GRADE LIST
export const getGradeList = () => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/grade`);
    // console.log(res);

    dispatch({
      type: GET_GRADE,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE GRADE
export const createGrade = (values) => async (dispatch) => {
  let formData = {
    name: values.name,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/grade`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: CREATE_GRADE,
    });
    dispatch(getGradeList());
    toast.success("Class added successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE GRADE
export const updateGrade = (values, id) => async (dispatch) => {
  let formData = {
    name: values.name,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/grade/${id}`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: UPDATE_GRADE,
    });

    dispatch(getGradeList());
    toast.success("Class updated successfully");
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Grade
export const deleteGrade = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/grade/${id}`);
    dispatch({
      type: DELETE_GRADE,
      payload: id,
    });
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
