import React from "react";
// import styles from "./PiChart.module.css";
import Chart from "react-apexcharts";
import useWindowDimensions from "../../hooks/windowDimensions";

const PiChart = ({ app, ap, am, a, b, c }) => {
  const { width } = useWindowDimensions();
  const colors = [
    "#34eb4c",
    "#3496eb",
    "#ebc334",
    "#eb8f34",
    "#eb6234",
    "#eb3434",
  ];
  const options = {
    options: {
      labels: ["A++", "A+", "A", "A-", "B", "C"],
      legend: {
        show: true,
        position: "bottom",
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          useSeriesColors: false,
          fillColors: colors,
        },
      },
      fill: {
        colors: colors,
        useSeriesColors: false,
      },
      markers: {
        colors: colors,
      },
      dataLabels: {
        enabled: true,
      },
      pie: {
        donut: {
          labels: {
            show: true,
            name: ["A++", "A+", "A", "A-", "B", "C"],
            value: [app, ap, a, am, b, c],
          },
        },
      },
    },
    series: [app, ap, a, am, b, c],
  };

  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <Chart
        options={options.options}
        series={options.series}
        type="donut"
        width={width < 767 ? "300px" : "700px"}
      />
    </div>
  );
};

export default PiChart;
