import React from "react";
import { IndividualResultForm } from "../../components/IndividualResultForm";
import Layout from "../../components/shared/Layout/Layout";

const IndividualResult = ({ week }) => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={week ? "Weekly Comparison" : "Individual Result"}>
        <IndividualResultForm week={week} />
      </Layout>
    </div>
  );
};

export default IndividualResult;
