import React from "react";
import Layout from "../../components/shared/Layout/Layout";
import { WeeklyTopResults } from "../../components/WeeklyTopResults";

const WeeklyTopResultPage = () => {
  return (
    <div
      className="bg_dark"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Layout title={`Weekly Top Result`}>
        <WeeklyTopResults />
      </Layout>
    </div>
  );
};

export default WeeklyTopResultPage;
