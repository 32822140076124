import axios from "axios";
import { toast } from "react-toastify";
import {
  CREATE_BATCH,
  DELETE_BATCH,
  GET_BATCH,
  GET_BATCH_STUDENTS,
  GET_GRADE_BATCH,
  UPDATE_BATCH,
} from "../constants/Type";
import { BASE_URL } from "../constants/URL";

//GET BATCH LIST
export const getBatchList = () => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/batch`);
    // console.log(res);

    dispatch({
      type: GET_BATCH,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

//GET BATCH LIST
export const getBatchStudent = (id, year) => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(
      `${BASE_URL}/api/batch/${id}${year ? `?year=${year}` : ""}`
    );
    // console.log(res);

    dispatch({
      type: GET_BATCH_STUDENTS,
      payload: res.data.data,
    });
    return res.data.data;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
//GET BATCH LIST
export const getGradeBatchList = () => async (dispatch) => {
  // console.log("HITT");
  try {
    const res = await axios.get(`${BASE_URL}/api/batch/all`);
    // console.log(res);

    dispatch({
      type: GET_GRADE_BATCH,
      payload: res.data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

// CREATE BATCH
export const createBatch = (values) => async (dispatch) => {
  let formData = {
    name: values.name,
    year: values.year,
    shiftId: values.shiftId,
    gradeId: values.gradeId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.post(
      `${BASE_URL}/api/batch`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: CREATE_BATCH,
      payload: res.data.data,
    });
    toast.success("Batch added successfully");

    dispatch(getGradeBatchList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

// UPDATE BATCH
export const updateBatch = (values, id) => async (dispatch) => {
  let formData = {
    name: values.name,
    year: values.year,
    shiftId: values.shiftId,
    gradeId: values.gradeId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    // TODO ::: API CALL
    const res = await axios.patch(
      `${BASE_URL}/api/batch/${id}`,
      JSON.stringify(formData),
      config
    );
    dispatch({
      type: UPDATE_BATCH,
      payload: res.data.data,
    });
    toast.success("Batch updated successfully");

    dispatch(getGradeBatchList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};

//DELETE Batch
export const deleteBatch = (id) => async (dispatch) => {
  try {
    await axios.delete(`${BASE_URL}/api/batch/${id}`);
    dispatch({
      type: DELETE_BATCH,
      payload: id,
    });
    dispatch(getGradeBatchList());
    return true;
  } catch (err) {
    toast.error(err.response.data.message);
    return false;
  }
};
